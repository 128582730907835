import React, { useState } from 'react';
import './PhotosPage.css';

const photos = [
  { id: 1, src: 'assets/thumbnails/papafish.png', fullSrc: 'assets/papafish.png', alt: 'Photo 1' },
  { id: 2, src: 'assets/thumbnails/velvetdeer.png', fullSrc: 'assets/velvetdeer.png', alt: 'Photo 2' },
  { id: 3, src: 'assets/thumbnails/turkey.png', fullSrc: 'assets/turkey.png', alt: 'Photo 3' },
  { id: 4, src: 'assets/thumbnails/monsterdeer.png', fullSrc: 'assets/monsterdeer.png', alt: 'Photo 4' },
  { id: 5, src: 'assets/thumbnails/hornmount.png', fullSrc: 'assets/hornmount.png', alt: 'Photo 5' },
  { id: 6, src: 'assets/thumbnails/hog.png', fullSrc: 'assets/hog.png', alt: 'Photo 6' },
  { id: 7, src: 'assets/thumbnails/fish2.png', fullSrc: 'assets/fish2.png', alt: 'Photo 7' },
  { id: 8, src: 'assets/thumbnails/fish1.png', fullSrc: 'assets/fish1.png', alt: 'Photo 8' },
  { id: 9, src: 'assets/thumbnails/mimideer.png', fullSrc: 'assets/mimideer.png', alt: 'Photo 9' },
  { id: 10, src: 'assets/thumbnails/joshdeer.png', fullSrc: 'assets/joshdeer.png', alt: 'Photo 10' },
  { id: 11, src: 'assets/thumbnails/deergroup.png', fullSrc: 'assets/deergroup.png', alt: 'Photo 11' },
  { id: 12, src: 'assets/thumbnails/image20.png', fullSrc: 'assets/image20.png', alt: 'Photo 12' },
  { id: 13, src: 'assets/thumbnails/image22.png', fullSrc: 'assets/image22.png', alt: 'Photo 13' },
  { id: 14, src: 'assets/thumbnails/ducks.png', fullSrc: 'assets/ducks.png', alt: 'Photo 14' }
];

const PhotosPage = () => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const openPhoto = (photo) => {
    setSelectedPhoto(photo);
  };

  const closePhoto = () => {
    setSelectedPhoto(null);
  };

  return (
    <div className="photos-page-container">
      <h1>Gallery</h1>
      <div className="grid-container">
        {photos.map(photo => (
          <div key={photo.id} className="grid-item" onClick={() => openPhoto(photo)}>
            <img
              src={photo.src}
              alt={photo.alt}
              className="grid-img"
              loading="lazy"
            />
          </div>
        ))}
      </div>

      {/* Full Photo Modal */}
      {selectedPhoto && (
        <div className="modal" onClick={closePhoto}>
          <div className="modal-content">
            <img src={selectedPhoto.fullSrc} alt={selectedPhoto.alt} className="full-img" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotosPage;
