import React from 'react';
import './PriceList.css';
import Header from './Header';

const PriceList = () => {
  return (
    <div className="price-list-container">
      <Header />

      <div className="pricepage-content">
        <h1>Pricing</h1>
        <hr className="green-line" />
        <p className="notice"><strong>Prices are subject to change without notice.</strong></p>
        <p>
          For inquiries, please call me at 
          <a href="tel:+18033312139" className="phone-link">(803) 331-2139</a>
        </p>
      </div>

      <div className="price-list-content">
        {/* Row 1: Three Tables */}
        <div className="table-row">
          <PriceTable
            title="Birds"
            description="Prices include a suitable base. Natural Habitat bases are custom-made at an additional cost."
            rows={[
              ['Duck', '$350 - $400'],
              ['Goose', '$750'],
              ['Grouse', '$375'],
              ['Pheasant', '$375'],
              ['Turkey (any pose)', '$1200'],
              ['Head, Neck & Tail', '$350'],
              ['Size of Quail', '$375'],
            ]}
          />
          <PriceTable
            title="European Skull Mounts"
            description="Animal's natural skull is cleaned, bleached, and treated."
            rows={[
              ['Bear', '$175'],
              ['Boar', '$250'],
              ['Cats', '$175'],
              ['Deer', '$175'],
              ['Elk/Caribou', '$225'],
              ['Fox', '$175'],
              ['Moose', '$325'],
            ]}
          />
          <PriceTable
            title="Game Mounts - Shoulder/Life-Size"
            rows={[
              ['Beaver', '-- / $1,200'],
              ['Boar', '$850 / --'],
              ['Bobcat', '-- / $1,200'],
              ['Coyote', '-- / $1,200'],
              ['Deer - Whitetail Buck', '$675 / --'],
              ['Deer - Mule', '$675 / --'],
              ['Fox', '-- / $1,200'],
              ['Otter', '-- / $1,050'],
              ['Raccoon', '-- / $1,050'],
              ['Squirrel/Weasel', '-- / $300'],
            ]}
          />
        </div>

        {/* Row 2: Two Tables */}
        <div className="table-row table-row-bottom">
          <PriceTable
            title="Horn Plaques"
            description="Fashioned with buckskin covering on a wood panel."
            rows={[
              ['Deer', '$110'],
            ]}
          />
          <PriceTable
            title="Fish"
            rows={[
              ['Bass', '$300 - $400'],
              ['Pan Fish', '$275'],
              ['Striper/Trout (per inch)', '$20'],
              ['Salt Water (per inch)', '$25'],
              ['Reproductions (per inch)', '$25'],
            ]}
          />
        </div>
      </div>
    </div>
  );
};

const PriceTable = ({ title, description, rows }) => (
  <div className="table-wrapper">
    <table>
      <thead>
        <tr>
          <th colSpan="2">{title}</th>
        </tr>
        {description && (
          <tr>
            <td colSpan="2" className="table-description">{description}</td>
          </tr>
        )}
      </thead>
      <tbody>
        {rows.map(([item, price], index) => (
          <tr key={index}>
            <td>{item}</td>
            <td>{price}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default PriceList;
