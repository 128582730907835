import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../MH.png';
import './Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  const handleMouseOver = (e) => {
    e.target.style.color = '#059652';
  }

  const handleMouseOut = (e) => {
    e.target.style.color = 'white';
  }

  return (
    <div className="container">
      <header className="header">
        <Link to="/" className="logo-container" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <img src={logo} alt="Mickey Herring Taxidermy" className="logo" />
          <span className="logo-text">Mickey Herring Taxidermy</span>
        </Link>
        <nav className={`nav ${isOpen ? 'open' : ''}`}>
          <ul>
            <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
            <li><Link to="/pricelist" onClick={toggleMenu}>Price List</Link></li>
            <li><Link to="/photos" onClick={toggleMenu}>Gallery</Link></li>
            <li><Link to="/reviews" onClick={toggleMenu}>Reviews</Link></li>
          </ul>
        </nav>
        <div className="hamburger" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </header>
      <div className="content">
        {/* Your main content goes here */}
      </div>
    </div>
  );
}

export default Header;
