import React from 'react';
import './ReviewsPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const reviews = [
  { id: 1, name: 'Darrel Bentley', text: 'My mount is so amazing. Really fast turn around. Very friendly as well.', rating: 5 },
  { id: 2, name: 'Angela Brazil', text: 'Absolutely amazing taxidermist! He makes every creature so life-like and to perfection.', rating: 5 },
  { id: 3, name: 'Bill Gallaher', text: 'Got my buck mounted to shoulder looks amazing on the wall.', rating: 4 },
  { id: 4, name: 'Carl Gable', text: 'This man is a true artist. I could not have asked for a better mount.', rating: 5 },
  { id: 5, name: 'Mike Herring', text: 'Great deer heads, excellent fish mounts as well.', rating: 5 },
  { id: 6, name: 'Micaiah Bouknight', text: 'Great job on wood duck mount!', rating: 5 },
  { id: 7, name: 'Jared Anderson', text: 'Had a raccoon done years ago still looks like the day I brought it home. Just dropped a Canadian goose off and I know the result will be the same! His work holds for years to come!', rating: 5 },
];

const ReviewsPage = () => {
  return (
    <div className="container mt-5">
      <h1>Reviews</h1>
      <div className="row">
        {reviews.map(review => (
          <div key={review.id} className="col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{review.name}</h5>
                <p className="card-text">{review.text}</p>
                <div className="star-rating">
                  {Array(review.rating).fill().map((_, i) => (
                    <span key={i} className="star">&#9733;</span>
                  ))}
                  {Array(5 - review.rating).fill().map((_, i) => (
                    <span key={i + review.rating} className="star empty">&#9734;</span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewsPage;
