import React from 'react';
import { Link } from 'react-router-dom';
import '../components/HomePage.css';

const HomePage = () => {
  return (
    <div className="home-container">
      <div className="overlay"></div>
      <div className="home-content">
        <h1>
          Welcome to<br />
          Mickey Herring<br />
          Taxidermy
        </h1>
        <hr className="green-line" />
        <p>As an avid hunter and fisherman, I know the importance of treasuring your wildlife trophies. I specialize in deer, turkey, and fish, but I'm always up for a new challenge.</p>
        <Link to="/pricelist"><button className="view-price-list-btn">View Price List</button></Link>
      </div>
    </div>
  );
}

export default HomePage;
